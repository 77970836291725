"use client";
import React, { useState } from "react";
import {
  NavigationMenuList,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
} from "~/components/ui/navigation-menu";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import {
  CloseOutline,
  HamburgerMenuIcon,
  SearchIcon,
} from "~/components/icons";
import NavBanner from "./nav-banner";
import MenuItem from "./menu-item";
import MenuCTAItem from "./menu-cta-item";
import BlogCard from "~/components/reusables/blog-card";
import ProductMenuCard from "~/components/reusables/product-menu-card";
import Link from "next/link";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "components/ui/search-dialog";
import { SearchBar } from "./search-bar";

const MobileNav = ({ menuItems, eyeBrowLink }) => {
  const [activeIndex, setActiveIndex] = useState("0");
    const [open, setOpen] = useState(false);
  
  return (
    <>
      <div>
        <NavigationMenuList className="flex flex-row-reverse gap-3">
          <NavigationMenuItem>
            <NavigationMenuTrigger className="p-0">
              <div
                className="hover:bg-blueA3 items-center justify-center text-white outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
                aria-label="Navigation Menu"
              >
                <div className="group-data-[state=open]:hidden">
                  <HamburgerMenuIcon size={25} />
                </div>
                <div className="hidden group-data-[state=open]:block">
                  <CloseOutline size={30} />
                </div>
              </div>
            </NavigationMenuTrigger>

            <NavigationMenuContent className="relative z-[10000] rounded-none bg-black pb-20 text-white">
              <div className="flex w-screen flex-col gap-4 p-8">
                <Accordion
                  type="single"
                  collapsible
                  defaultValue={"none"}
                  className="mt-5 border-t border-sky-blue"
                >
                  {menuItems?.map((item, itemIndex) => {
                    return (
                      <AccordionItem
                        value={itemIndex.toString()}
                        className={
                          itemIndex == menuItems.length - 1
                            ? "border-none"
                            : "border-sky-blue"
                        }
                        key={itemIndex}
                      >
                        <AccordionTrigger
                          onClick={() => setActiveIndex(itemIndex)}
                          className=""
                        >
                          <div className="relative flex items-center gap-3">
                            {item.title == "Blog" ? (
                              <Link href={eyeBrowLink.url}>
                                <h4 className="font-h4 text-primary-gray group-data-[state=open]:text-white">
                                  {eyeBrowLink.text}
                                </h4>
                              </Link>
                            ) : (
                              <h4 className="font-h4 text-primary-gray group-data-[state=open]:text-white">
                                {item.title}
                              </h4>
                            )}

                            {item.title == "Blog" && (
                              <div>
                                <div className="font-btn-lg block rounded-xl border border-blue-light px-3 py-1 font-medium">
                                  <p className="text-[10px] text-sky-blue no-underline">
                                    {eyeBrowLink.eyebrow}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </AccordionTrigger>
                        <AccordionContent className="px-2 text-md font-extralight">
                          <div className="flex flex-col gap-3 py-5">
                            <MenuItem
                              title={item.menu1Title}
                              items={item.menu1Items}
                            />
                            <MenuItem
                              title={item.menu2Title}
                              items={item.menu2Items}
                            />
                            <MenuItem
                              title={item.menu3Title}
                              items={item.menu3Items}
                            />
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
              {typeof activeIndex == "number" ? (
                <div className="px-8">
                  <MenuCTAItem cta={menuItems[activeIndex].CTA} />
                  {menuItems[activeIndex].featuredBlogs.data.length > 0 && (
                    <div className="py-5">
                      <h6 className="font-h6">
                        {menuItems[activeIndex].featuredTitle}
                      </h6>
                      <div className="grid grid-cols-1 gap-5 pt-5 md:grid-cols-2">
                        {menuItems[activeIndex].featuredBlogs.data.map(
                          (blog, i) => (
                            <div key={`blog-${i}`}>
                              <BlogCard blog={blog} size="menu" />
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                  {menuItems[activeIndex].featuredCard.length > 0 && (
                    <div className="py-5">
                      <h6 className="font-h6">
                        {menuItems[activeIndex].featuredTitle}
                      </h6>
                      <div
                        className={"grid grid-cols-1 gap-5 pt-5 md:grid-cols-2"}
                      >
                        {" "}
                        {menuItems[activeIndex].featuredCard.map(
                          (product, i) => (
                            <div key={`blog-${i}`}>
                              <ProductMenuCard
                                product={product}
                                horizontalLayout={true}
                              />
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
              <NavBanner item={menuItems[parseInt(activeIndex)]} />
            </NavigationMenuContent>
          </NavigationMenuItem>
          {/* <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger className="p-0">
              <SearchIcon color="#B5B5B5" size={25} />
            </DialogTrigger>
            <DialogContent className="">
              <div className="flex-grow">
                  <SearchBar setOpen={setOpen} />
              </div>
            </DialogContent>
          </Dialog> */}
        </NavigationMenuList>
      </div>
    </>
  );
};

export default MobileNav;
